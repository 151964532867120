import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["appFeature", "interface"]

  connect() {
    this.addOnClickEventListeners()
    this.displayInterface(0)
    this.highlight(this.appFeatureTargets[0])
  }

  addOnClickEventListeners(){
    for (const [index, itemTarget] of this.appFeatureTargets.entries()) {
      itemTarget.addEventListener("click", () => {
        this.highlight(itemTarget)
        this.displayInterface(index)
      })
    }
  }

  displayInterface(interfaceToDisplayIndex) {
    for (const [index, interfaceTarget] of this.interfaceTargets.entries()) {
      if (index === interfaceToDisplayIndex) this.displayContent(interfaceTarget)
      else this.hideContent(interfaceTarget)
    }
  }

  highlight(currentItem) {
    for (const itemTarget of this.appFeatureTargets) {
      if (itemTarget === currentItem) this.addHighlighting(itemTarget)
      else this.removeHighlighting(itemTarget)
    }
  }

  addHighlighting(item) {
    item.classList.add("bg-blue-0")
    item.classList.remove("border-neutral-20")
    item.classList.add("border-blue-30-primary")
  }

  removeHighlighting(item) {
    item.classList.remove("bg-blue-0")
    item.classList.remove("border-blue-30-primary")
    item.classList.add("border-neutral-20")
  }

  displayContent(item) {
    item.classList.remove("hidden")
  }

  hideContent(item) {
    item.classList.add("hidden")
  }
}
